<template>
    <div :class="`body __${system.fontSize} __${system.theme} __md __${bodyType}`">
        <TheBeforeCommon v-if="commonBefore" />
        <!-- BEFORE -->
        <NuxtLoadingIndicator :color="'rgb(124, 164, 221, .5)'" />
        <slot name="main" />
        <div v-if="!system.loaded" id="pr--preloading" class="pr--preloading __fixed __animated">
            <div />
        </div>
        <!-- AFTER -->
        <TheAfterCommon v-if="commonAfter" />
    </div>
</template>

<script setup lang="ts">

import fs from 'fs';
import { useAsyncData, useNuxtApp } from 'nuxt/app';
import { watch, defineProps } from 'vue';
import { useRoute } from 'vue-router';
import TheAfterCommon from '@/components/TheAfterCommon.vue';
import TheBeforeCommon from '@/components/TheBeforeCommon.vue';
import { useStoreAppErrorIndex } from '@/stores/app/error';
import { useStoreAppSidebarIndex } from '@/stores/app/sidebar';
import { useStoreSystem } from '@/stores/system';
const route = useRoute();
const error = useStoreAppErrorIndex();
const system = useStoreSystem();
const sidebar = useStoreAppSidebarIndex();

export interface Props {
    commonBefore?: boolean,
    commonAfter?: boolean,
    bodyType?: string,
}

const props = withDefaults(defineProps<Props>(), {
    commonBefore: true,
    commonAfter: true,
    bodyType: 'default',
});

if (process.server) {
    console.log('--------------- ssr start ---------------');
    system.setRoute({
        changed: false,
        name: route.name,
        path: route.path.slice(1),
        fullPath: route.fullPath,
    });
}
//
if (process.client) {
    system.setLayout(route.meta.layout);
    // useStoreAppVersion().fetchVs((vs: any) => {
    //     console.log(vs);
    // });
    watch(() => route.meta.layout, () => {
        system.setLayout(route.meta.layout);
    });
    watch(() => route.fullPath, () => {
        system.setRoute({
            changed: true,
            name: route.name,
            path: route.path.slice(1),
            fullPath: route.fullPath,
        });
        if (error.active) {
            error.setError(null);
        }
        sidebar.setOpened(false);
        system.setNavigationChanged();

        // const breadcrumbs = document.querySelector('.pr--breadcrumb').offsetWidth;
        // const breadcrumbActive = document.querySelector('.breadcrumb-item.active').offsetWidth;
        //
        // console.log(breadcrumbs, breadcrumbActive);
    });
}

</script>
